import React from "react";
import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <Header></Header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="active first overview last">
                <a href="/woordenlijst/"> Woordenlijst diarree</a>
              </li>
            </ul>
            <div className="widget">
              <div className="inner">
                <h4>Hoe werkt IMODIUM®?</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/widget.jpeg"
                    width="170"
                    height="102"
                    alt="Hoe werkt IMODIUM®?"
                  />
                </div>
                <p>
                  Volgend filmpje toont je de werking van{" "}
                  <strong>
                    IMODIUM<sup>®</sup>
                  </strong>
                  : hoe <strong>diarree</strong> belangrijke vloeistoffen en
                  mineralen aan je lichaam onttrekt en hoe{" "}
                  <strong>
                    IMODIUM<sup>®</sup>{" "}
                  </strong>
                  het natuurlijke evenwicht in je darm herstelt.
                </p>
                <a href="/hoe-werkt-imodium-instant/">Meer informatie</a>
              </div>
            </div>
            <div className="widget">
              <div className="inner">
                <h4>Tips bij diarree</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/teaser_durchfallmythen.jpeg"
                    width="170"
                    height="102"
                    alt="Tips bij diarree"
                  />
                </div>
                <p>
                  Rond het behandelen van de symptomen van diarree bestaan er
                  nog vele misverstanden en&nbsp;mythes. Hier vind je enkele
                  tips over het behandelen van de symptomen van diarree.
                </p>
                <a href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/">
                  Tips bij diarree
                </a>
              </div>
            </div>
          </div>
          <div className="page nine columns page-41">
            <h1>Woordenlijst diarree</h1>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/abdominaal/">Abdominaal</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Met betrekking tot het abdomen. Ook bekend als de buik, met
                  name het gedeelte van het lichaam tussen de borstkas en het
                  bekken. Het abdomen bevat organen zoals de maag, de dunne
                  darm, de dikke darm, het rectum en de blaas.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/acute-diarree/">Acute diarree</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Acute diarree is een plotse aanval van diarree die minder dan
                  twee weken duurt.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/allergie/">Allergie</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Een reactie op vreemde substanties door het immuunsysteem van
                  het lichaam. Substanties die een allergie uitlokken, worden
                  allergenen genoemd, bijvoorbeeld pollen, stofmijten en
                  bepaalde voedingsmiddelen.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/antibiotica/">Antibiotica</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Geneesmiddelen die worden gebruikt voor de behandeling van
                  infecties veroorzaakt door schadelijke bacteriën en andere
                  micro-organismen.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/antispasmodisch-middel/">
                    Antispasmodisch middel
                  </a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>Een geneesmiddel dat spierkrampen vermindert of voorkomt.</p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/bacterien/">Bacteriën</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Ook microben genoemd; deze eencellige micro-organismen worden
                  vaak in verband gebracht met infecties, maar sommige zijn
                  onschadelijk en/of gunstig voor de mens.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/centraal-zenuwstelsel/">
                    Centraal zenuwstelsel
                  </a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Het centraal zenuwstelsel (CZS) is het gedeelte van het
                  zenuwstelsel dat bestaat uit de hersenen en de ruggengraat.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/chronische-diarree/">
                    Chronische diarree
                  </a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Men spreekt van chronische diarree wanneer de symptomen langer
                  dan vier weken aanhouden.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/darmen/">Darmen</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Deel van het spijsverteringsstelsel, bestaande uit de dunne en
                  de dikke darm, die in het abdomen liggen.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/dehydratatie/">Dehydratatie</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Wanneer het lichaam overmatig veel water verliest. Langdurig
                  braken of diarree kunnen leiden tot dehydratatie, evenals
                  blootstelling aan hitte, langdurige zware fysieke
                  inspanningen, en een aantal geneesmiddelen.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/diarree/">Diarree</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Abnormaal losse of vloeibare stoelgang die meer dan drie keer
                  per dag voorkomt, en/of stoelgang met een volume van meer dan
                  200g per dag.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/flatulentie-of-winderigheid/">
                    Flatulentie of winderigheid
                  </a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Overmatige gasvorming in de darmen. 14 keer gas uitdrijven in
                  een periode van 24 uur wordt als ‘normaal’ beschouwd.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/gastro-enteritis-of-buikgriep/">
                    Gastro-enteritis
                  </a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Een ontsteking van de maag en de darmen, die misselijkheid,
                  braken en/of diarree kan veroorzaken. Virussen,
                  voedselvergiftiging en stress kunnen allemaal gastro-enteritis
                  veroorzaken.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/immuunsysteem/">Immuunsysteem</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Het natuurlijke verdedigingssysteem van ons lichaam. Het
                  herkent vreemde substanties en beschermt ons tegen infecties.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/laxeermiddelen/">Laxeermiddelen</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Geneesmiddelen die de darmen soepeler maken om constipatie
                  tegen te gaan; kunnen diarree veroorzaken bij overmatig
                  gebruik.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/loperamide/">Loperamide</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Een geneesmiddel dat wordt gebruikt bij de behandeling van
                  diarree door de contractie van de spieren in de darm te
                  vertragen.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/menstruatie/">Menstruatie</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Periodieke bloeding uit de baarmoeder, die ongeveer om de 4
                  weken optreedt, en aldus voor de menstruele cyclus zorgt.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/misselijkheid/">Misselijkheid</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Dit is een aandrang om te braken, die kan worden veroorzaakt
                  door veel dingen, zoals ziekte, geneesmiddelen en onevenwicht
                  in het binnenoor.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/norovirus/">Norovirus</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Er zijn eigenlijk veel soorten norovirussen; samen maken ze
                  deel uit van een groep virussen die de meest voorkomende
                  oorzaak van gastro-enteritis zijn. Hoewel ze mensen het hele
                  jaar door kunnen besmetten, wordt ze vaak 'winter vomiting
                  disease' genoemd omdat ze het meest voorkomt in de winter.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/nutrienten-of-voedingsstoffen/">
                    Nutriënten of voedingsstoffen
                  </a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Substanties uit het voedsel die het lichaam nodig heeft voor
                  energie, groei en herstel, zoals vitamines, mineralen,
                  koolhydraten, vetten en eiwitten.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/opgeblazen-gevoel/">
                    Opgeblazen gevoel
                  </a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Een opgeblazen gevoel wordt vaak in verband gebracht met het
                  abdomen, wanneer darmgassen zorgen voor een vol en gespannen
                  gevoel van het abdomen.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/peristaltische-beweging/">
                    Peristaltische beweging
                  </a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  De beweging van de spieren in onze darmen die samentrekken en
                  ontspannen in ritmische golven, waardoor de inhoud van de darm
                  wordt voortgeduwd. Bij deze beweging wordt voedsel ook gemengd
                  met maagzuren, waardoor het een dunne vloeistof wordt.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/rehydratatie/">Rehydratatie</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Het proces van het verloren water opnieuw in het lichaam te
                  brengen, ofwel door vloeistoffen te drinken, ofwel intraveneus
                  (rechtstreeks in de aderen).
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/sigmoidoscopie/">Sigmoïdoscopie</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Een onderzoek van het rectum en de karteldarm, aan de hand van
                  een dunne, verlichte buis, een sigmoïdoscoop, waarmee weefsel
                  of cellen kunnen worden verzameld voor verder onderzoek.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/simeticon/">Simeticon</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Een werkzame stof die helpt gassen op te lossen die voor
                  winderigheid zorgen.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/slijm/">Slijm</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Een dikke, glibberige vloeistof die wordt geproduceerd door
                  membranen rond bepaalde organen, zoals de neus, de mond, de
                  keel en de vagina.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/stoelgang/">Stoelgang</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  De vaste afvalmaterie die door het rectum gaat in de vorm van
                  darmbewegingen. Stoelgang bestaat uit onverteerd voedsel,
                  bacteriën, slijm en dode cellen.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/symmptoom/">Symptoom</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Een fysiek of mentaal teken dat iemand een aandoening of
                  ziekte heeft. Diarree, hoofdpijn, koorts, vermoeidheid,
                  misselijkheid, pijn en depressie zijn veel voorkomende
                  symptomen.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/constipatie/">Verstopping</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Niet frequente, en vaak onvolledige, darmbewegingen; het
                  tegenovergestelde van diarree.
                </p>
                <p />
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
        <div id="myModal" className="reveal-modal large">
          <div
            className="flash-wrapper"
            style={{
              "max-width": "100%",
              height: "236px",
              width: "428px",
              margin: "0 auto"
            }}
          >
            <div id="flash">
              <p>
                Your browser must have Adobe Flash installed to see this
                animation.
              </p>
            </div>
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="nl-BE" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
         <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script src="/assets/js/custom.js" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
         
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\ncookiesDirective('bottom',5,'/cookiebeleid');\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
